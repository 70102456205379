.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.shadow {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
}

.content {
  background-color: white;
  padding: 20px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto', sans-serif;
}

.App-link {
  color: #61dafb;
  font-family: 'Anton', sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home {
  text-align: center;
}

.Home .lander {
  padding: 80px 0;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.NewItem form {
  padding-bottom: 15px;
}

.NewItem form textarea {
  height: 300px;
  font-size: 24px;
}