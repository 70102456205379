html {
  background-color: #f2f2f2;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Anton", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("/arrow_pattern.svg");
  background-size: 100px 100px;
  height: 100%;
}


h1, h2, h3, h4, h5, h6, a, button {
  font-family: "Oswald", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.btn-bluecolor,
.btn-bluecolor:disabled,
.btn-bluecolor:hover,
.btn-bluecolor:not(:disabled):not(:disabled):active {
  background-color: #353B44;
  border-color: #353B44;
}